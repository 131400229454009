<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar flat class="primary--text text-h6">
            {{ tableTitle }}
          </v-toolbar>
          <search-and-filter :placeholder="placeholder"></search-and-filter>
          <v-data-table
            class="row-pointer"
            :headers="userHeader"
            :items="users"
            :items-per-page="pagination.limit"
            :loading="loadUsers"
            hide-default-footer
            @click:row="userDetails"
          >
            <template v-slot:item.numbers="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:item.name="{ item }">
              {{ item.fullName }}
            </template>
            <template v-slot:item.balance="{ item }">
              {{ helpers.formatAmount(userBalance(item)) }}
            </template>
            <template v-slot:item.lastDeposit="{ item }">
              {{ helpers.formatAmount(item.lastDeposit) }}
            </template>
            <template v-slot:item.dailyLimit="{ item }">
              {{ helpers.formatAmount(item.dailyLimit) }}
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ helpers.formatDate(item.createdAt) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <div
      v-if="pagination.total > pagination.limit"
      class="my-4 d-flex justify-end"
    >
      <v-pagination
        v-model="pagination.page"
        :length="Math.ceil(pagination.total / pagination.limit)"
        :total-visible="pagination.total < 3 ? 2 : 6"
        @input="getNext"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Helpers from "../../utils/helpers";
import searchAndFilter from "@/components/searchAndFilter.vue";

export default {
  name: "DashboardPage",
  layout: "authenticated",
  components: {
    searchAndFilter,
  },
  data() {
    return {
      loadUsers: false,
      helpers: new Helpers(),
      userHeader: [
        { text: "#", value: "numbers", sortable: false, orderable: false },
        { text: "Full Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Account Number", value: "AccountNumber.account_number" },
        { text: "Balance", value: "balance" },
        { text: "Last Deposit", value: "lastDeposit" },
        { text: "Daily Limit", value: "dailyLimit" },
        { text: "Status", value: "status" },
        { text: "Date Registered", value: "createdAt" },
      ],
      users: [],
      placeholder: "search... name, email, phone, account number",
      pagination: {
        limit: 5,
        total: 0,
        page: 1,
      },
    };
  },
  computed: {
    tableTitle() {
      return this.$route.path === "/users"
        ? "All Users"
        : "Recent Registrations";
    },
  },
  methods: {
    ...mapActions({
      get_users: "users/get_users",
      get_summary: "users/get_summary",
    }),
    async getUsers(filter = null, filtered = null) {
      let data = filter;
      if (!filtered) {
        data = `?page=${this.pagination.page}&limit=${this.pagination.limit}`;
        this.$root.$emit("filtered", false);
      } else {
        this.$root.$emit("filtered", true);
      }

      this.loadUsers = true;
      try {
        const response = await this.get_users(data);
        const resp = response.data;
        this.users = resp.users;
        this.pagination.total = resp.itemCount;
        this.pagination.page = resp.currentPage;
        this.loadUsers = false;
      } catch (e) {
        this.loadUsers = false;
      }
    },
    async nextPage(filter, filtered) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      Promise.all([this.getSummary(filter), this.getUsers(filter, filtered)]);
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
    },
    async getSummary(filter = null) {
      try {
        const response = await this.get_summary(filter ? filter : "?");
        const data = response.data;
        this.$parent.totalUsers = data.totalUsers;
        this.$parent.activeUsers = data.activeUsers;
        this.$parent.inactiveUsers = data.inactiveUsers;
        this.$parent.suspendedUsers = data.suspendedUsers;
        this.$parent.totalUsersBalance = data.totalUsersBalance;
        this.$parent.totalAdminBalance = data.totalAdminBalance;
      } catch (e) {
        this.helpers.logConsole(e);
      }
    },
    async userDetails(value) {
      this.helpers.logConsole(value);
    },
    getNext() {
      this.$root.$emit("paginate", this.pagination);
    },
    userBalance(user) {
      return user?.Wallet?.balance || "0.00";
    },
    accountNumber(user) {
      return user?.accountNumber?.account_number || "";
    },
  },
  created() {
    this.getUsers();
    this.getSummary();
  },
  mounted() {
    this.$root.$on("filter-users", (data, filtered) => {
      const { pagination } = data;
      this.pagination = Object.assign({}, pagination);
      const filter = `?page=${pagination.page}&limit=${pagination.limit}&search=${data.search}&start_date=${data.start_date}&end_date=${data.end_date}`;
      this.nextPage(filter, filtered);
    });
  },
};
</script>

<style scoped></style>
