<template>
  <div>
    <div class="d-block d-md-flex align-md-center px-4" style="gap: 10px">
      <div class="d-flex align-baseline">
        <small class="font-weight-bold mr-2">Number of rows</small>
        <v-select
          class=""
          outlined
          dense
          flat
          style="width: 90px"
          :items="[5, 10, 25, 50, 100]"
          v-model="pagination.limit"
          @change="updateLimit"
        ></v-select>
      </div>
      <div>
        <v-text-field
          v-model="search"
          outlined
          dense
          flat
          style="width: 450px"
          :placeholder="placeholder"
          @keyup="searchEntity($event, search !== '')"
          clearable
        ></v-text-field>
      </div>
      <div
        class="d-flex mt-md-n7 align-center"
        style="cursor: pointer"
        @click="dialog = !dialog"
      >
        <small class="font-weight-bold mr-2">Filter by date</small>
        <v-icon size="40">mdi-filter-variant</v-icon>
      </div>
      <v-btn
        depressed
        color="primary"
        v-if="filtered"
        class="d-flex mt-md-n7 align-center text-capitalize"
        @click="clearFilters"
        >Clear filters
      </v-btn>
    </div>
    <v-dialog max-width="400" v-model="dialog">
      <v-card class="pa-4">
        <v-toolbar class="d-flex justify-center" flat>
          <v-card-title> Filter transaction by date </v-card-title>
        </v-toolbar>
        <v-divider></v-divider>
        <v-row class="text-left mt-4">
          <v-col>
            <div>From</div>
            <v-text-field
              v-model="start_date"
              outlined
              dense
              flat
              type="date"
            ></v-text-field>
          </v-col>
          <v-col>
            <div>To</div>
            <v-text-field
              v-model="end_date"
              outlined
              dense
              flat
              type="date"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed class="close mr-3" @click="closeDialog"
            >Close
          </v-btn>
          <v-btn
            depressed
            class="primary"
            :loading="loading"
            @click="filterByDate"
            >Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
  },
  name: "searchAndFilter",
  data() {
    return {
      search: "",
      searchTimer: null,
      dialog: false,
      start_date: "",
      end_date: "",
      filtered: false,
      loading: false,
      pagination: {
        limit: 5,
        page: 1,
        total: 0,
      },
      activePage: "",
      currentPage: "",
      cleared: false,
    };
  },
  watch: {
    search(oldVal, newVal) {
      if (oldVal !== newVal) {
        if (!oldVal) {
          this.search = "";
          this.filtered = false;
          this.start_date = this.end_date = "";
          if (!this.cleared) {
            this.searchEntity(false);
          }
        }
      }
    },
  },
  computed: {
    filterPayload() {
      return {
        search: this.search,
        pagination: this.pagination,
        start_date: this.start_date,
        end_date: this.end_date,
      };
    },
  },
  methods: {
    async updateLimit() {
      const payload = Object.assign({}, this.filterPayload);
      payload.pagination.page = 1;
      this.$root.$emit(this.activePage, payload, true);
    },
    async searchEntity(event, filtered = false) {
      this.cleared = false;
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }

      const pagination = this.pagination;
      pagination.page = 1;
      pagination.limit = 5;
      this.searchTimer = setTimeout(
        () => this.$root.$emit(this.activePage, this.filterPayload, filtered),
        1000
      );
    },
    closeDialog() {
      this.dialog = false;
    },
    async filterByDate() {
      this.closeDialog();
      this.pagination.page = 1;
      this.$root.$emit(this.activePage, this.filterPayload, true);
    },
    async clearFilters() {
      this.start_date = this.end_date = this.search = "";
      this.filtered = false;
      this.pagination.page = 1;
      this.pagination.limit = 5;
      this.cleared = true;
      this.$root.$emit(this.activePage, this.filterPayload, null);
    },
  },
  created() {},
  mounted() {
    this.activePage = `filter-${this.$route.path.replace("/", "")}`;
    if (this.activePage.indexOf("dashboard") > -1) {
      this.activePage = "filter-users";
    }
    const page = this.$route.path.replace("/", "");
    this.currentPage = `${page.charAt(0).toUpperCase()}${page.substring(
      1,
      page.legnth
    )}`;
    this.$root.$on("filtered", (data) => (this.filtered = data));
    this.$root.$on("paginate", (data) => {
      this.pagination = Object.assign({}, data);
      this.$root.$emit(this.activePage, this.filterPayload, true);
    });
  },
};
</script>

<style scoped></style>
