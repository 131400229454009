var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-toolbar',{staticClass:"primary--text text-h6",attrs:{"flat":""}},[_vm._v(" "+_vm._s(_vm.tableTitle)+" ")]),_c('search-and-filter',{attrs:{"placeholder":_vm.placeholder}}),_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.userHeader,"items":_vm.users,"items-per-page":_vm.pagination.limit,"loading":_vm.loadUsers,"hide-default-footer":""},on:{"click:row":_vm.userDetails},scopedSlots:_vm._u([{key:"item.numbers",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fullName)+" ")]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.helpers.formatAmount(_vm.userBalance(item)))+" ")]}},{key:"item.lastDeposit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.helpers.formatAmount(item.lastDeposit))+" ")]}},{key:"item.dailyLimit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.helpers.formatAmount(item.dailyLimit))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.helpers.formatDate(item.createdAt))+" ")]}}])})],1)],1)],1),(_vm.pagination.total > _vm.pagination.limit)?_c('div',{staticClass:"my-4 d-flex justify-end"},[_c('v-pagination',{attrs:{"length":Math.ceil(_vm.pagination.total / _vm.pagination.limit),"total-visible":_vm.pagination.total < 3 ? 2 : 6},on:{"input":_vm.getNext},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }